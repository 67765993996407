<template>
  <div>
    <spinner-loader :loadingStuff="loadingStuff" />
    <app-template />
    <div v-if="userData.name !== ''" class="uk-container uk-container-large">
      <h1>Document library</h1>
      <div class="sessions-week-layout">
        <!--<b-button
          class="main-button create-academy-report-button"
          size="lg"
          variant="outline-success"
          @click="showModal('documentModal')"
          ><b-icon icon="file-text" aria-hidden="true"></b-icon> Create
          document</b-button
        >-->

        <b-button
          class="main-button create-academy-report-button"
          size="lg"
          variant="outline-success"
          @click="showModal('documentNewEditModal')"
          ><b-icon icon="file-text" aria-hidden="true"></b-icon> Upload
          document</b-button
        >
        <!--<div class="sorting-wrapper">
          <select class="filter-by">
            <option :value="null">View documents by</option>
            <option>All</option>
            <option>Team</option>
            <option>Player</option>
          </select>
          <b-input-group size="sm" class="mb-2 autocomplete search">
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              type="search"
              placeholder="Search documents"
              @input="lookUpSearch($event)"
            ></b-form-input>
          </b-input-group>
        </div>-->
        <ul class="tabs">
          <li class="tab-item">
            <a
              :class="
                selectedTab === 'club-tab' ? 'tab-link active' : 'tab-link'
              "
              @click="tabChange('selectedTab', 'club-tab')"
              href="javascript:;"
              >Club</a
            >
          </li>
          <li class="tab-item">
            <a
              :class="
                selectedTab === 'team-tab' ? 'tab-link active' : 'tab-link'
              "
              @click="tabChange('selectedTab', 'team-tab')"
              href="javascript:;"
              >Teams</a
            >
          </li>
          <li class="tab-item">
            <a
              :class="
                selectedTab === 'player-tab' ? 'tab-link active' : 'tab-link'
              "
              @click="tabChange('selectedTab', 'player-tab')"
              href="javascript:;"
              >Players</a
            >
          </li>
          <li class="tab-item">
            <a
              :class="
                selectedTab === 'admin-tab' ? 'tab-link active' : 'tab-link'
              "
              @click="tabChange('selectedTab', 'admin-tab')"
              href="javascript:;"
              >Admin</a
            >
          </li>
          <li class="tab-item">
            <a
              :class="
                selectedTab === 'physioMain-tab'
                  ? 'tab-link active'
                  : 'tab-link'
              "
              @click="tabChange('selectedTab', 'physioMain-tab')"
              href="javascript:;"
              >physio</a
            >
          </li>
          <li class="tab-item">
            <a
              :class="
                selectedTab === 'global-tab' ? 'tab-link active' : 'tab-link'
              "
              @click="tabChange('selectedTab', 'global-tab')"
              href="javascript:;"
              >Global</a
            >
          </li>
        </ul>
        <div class="tabs-wrapper">
          <div
            class="current-week-tab documents-tab tab-content"
            v-if="selectedTab === 'club-tab'"
          >
            <!--<div
              class="document"
              v-for="document in usersDocuments"
              :key="document.id"
            >
              <img src="../img/documentlib.svg" />
              <h3>{{ document.name }}</h3>

              <div class="doc-buttons">
                <b-icon
                  @click="openDocument(document)"
                  icon="eye"
                  aria-hidden="true"
                ></b-icon>
                <b-icon
                  @click="editDocument(document)"
                  icon="pencil"
                  aria-hidden="true"
                ></b-icon>
                <b-icon icon="trash" aria-hidden="true"></b-icon>
              </div>
            </div>-->

            <div
              class="document"
              v-for="document in filterUploadedDocuments('club')"
              :key="document.id"
              v-b-tooltip.hover
              :title="document.name"
            >
              <img src="../img/documentlib.svg" />
              <h3>{{ document.name }}</h3>

              <div class="doc-buttons">
                <b-icon
                  @click="removeUploadedDocument(document.id, document.name)"
                  icon="trash"
                  aria-hidden="true"
                ></b-icon>
                <a :href="`./${document.url}`" target="_blank"
                  ><b-icon
                    @click="`./${document.url}`"
                    icon="eye"
                    aria-hidden="true"
                  ></b-icon
                ></a>
              </div>
            </div>

            <div v-if="filterUploadedDocuments('club').length === 0">
              <div class="empty-state">
                <img src="../img/emptyStateIllustration.svg" />
                <p>No session effort rating data</p>
              </div>
            </div>
          </div>

          <div
            class="document-uploads-tab documents-tab tab-content"
            v-if="selectedTab === 'team-tab'"
          >
            <div
              class="document"
              v-for="document in filterUploadedDocuments('team')"
              :key="document.id"
              v-b-tooltip.hover
              :title="removeNumberFromStart(document.name)"
            >
              <img src="../img/documentlib.svg" />
              <h3>{{ removeNumberFromStart(document.name) }}</h3>

              <div class="doc-buttons">
                <b-icon
                  @click="removeUploadedDocument(document.id, document.name)"
                  icon="trash"
                  aria-hidden="true"
                ></b-icon>
                <a :href="`./${document.url}`" target="_blank"
                  ><b-icon
                    @click="`./${document.url}`"
                    icon="eye"
                    aria-hidden="true"
                  ></b-icon
                ></a>
              </div>
            </div>

            <div v-if="filterUploadedDocuments('team').length === 0">
              <div class="empty-state">
                <img src="../img/emptyStateIllustration.svg" />
                <p>No session effort rating data</p>
              </div>
            </div>
          </div>
          <div
            class="document-uploads-tab documents-tab tab-content"
            v-if="selectedTab === 'player-tab'"
          >
            <div
              class="document"
              v-for="document in filterUploadedDocuments('player')"
              :key="document.id"
              v-b-tooltip.hover
              :title="document.name"
            >
              <img src="../img/documentlib.svg" />
              <h3>{{ document.name }}</h3>

              <div class="doc-buttons">
                <b-icon
                  @click="removeUploadedDocument(document.id, document.name)"
                  icon="trash"
                  aria-hidden="true"
                ></b-icon>
                <a :href="`./${document.url}`" target="_blank"
                  ><b-icon
                    @click="`./${document.url}`"
                    icon="eye"
                    aria-hidden="true"
                  ></b-icon
                ></a>
              </div>
            </div>

            <div v-if="filterUploadedDocuments('player').length === 0">
              <div class="empty-state">
                <img src="../img/emptyStateIllustration.svg" />
                <p>No session effort rating data</p>
              </div>
            </div>
          </div>
          <div
            class="document-uploads-tab documents-tab tab-content"
            v-if="selectedTab === 'admin-tab'"
          >
            <div
              class="document"
              v-for="document in filterUploadedDocuments('admin')"
              :key="document.id"
              v-b-tooltip.hover
              :title="document.name"
            >
              <img src="../img/documentlib.svg" />
              <h3>{{ document.name }}</h3>

              <div class="doc-buttons">
                <b-icon
                  @click="removeUploadedDocument(document.id, document.name)"
                  icon="trash"
                  aria-hidden="true"
                ></b-icon>
                <a :href="`./${document.url}`" target="_blank"
                  ><b-icon
                    @click="`./${document.url}`"
                    icon="eye"
                    aria-hidden="true"
                  ></b-icon
                ></a>
              </div>
            </div>

            <div v-if="filterUploadedDocuments('admin').length === 0">
              <div class="empty-state">
                <img src="../img/emptyStateIllustration.svg" />
                <p>No session effort rating data</p>
              </div>
            </div>
          </div>

          <div class="document-uploads-tab" v-if="selectedTab === 'global-tab'">
            <div class="signed-documents-wrapper">
              <h4>Signed documents</h4>

              <div class="signed-documents">
                <b-button
                  v-if="
                    userData.userType === 'Admin' ||
                    userData.userType === 'Coach'
                  "
                  @click="charterModal()"
                  size="lg"
                  class="main-button"
                  variant="outline-warning"
                  ><b-icon icon="pencil" aria-hidden="true"></b-icon> Edit
                  charter</b-button
                >

                <b-button
                  v-if="
                    userData.userType === 'Admin' ||
                    userData.userType === 'Coach'
                  "
                  @click="codeOfConductModal()"
                  size="lg"
                  class="main-button"
                  variant="outline-warning"
                  ><b-icon icon="pencil" aria-hidden="true"></b-icon> Edit Code
                  of conduct</b-button
                >

                <b-button
                  v-if="
                    userData.userType === 'Admin' ||
                    userData.userType === 'Coach'
                  "
                  @click="charterModal('parents')"
                  size="lg"
                  class="main-button"
                  variant="outline-warning"
                  ><b-icon icon="pencil" aria-hidden="true"></b-icon> Edit Fair
                  processing</b-button
                >
              </div>
            </div>

            <div class="documents">
              <h4>Documents</h4>

              <b-button
                v-if="
                  userData.userType === 'Admin' || userData.userType === 'Coach'
                "
                @click="playerAssesmentModal()"
                size="lg"
                class="main-button"
                variant="outline-warning"
                ><b-icon icon="pencil" aria-hidden="true"></b-icon> Edit U11-18
                Player Self Assessment</b-button
              >
            </div>
          </div>

          <div
            class="document-uploads-tab documents-tab tab-content"
            v-if="selectedTab === 'physioMain-tab'"
          >
            <div
              class="document"
              v-for="document in filterUploadedDocuments('physioMain')"
              :key="document.id"
              v-b-tooltip.hover
              :title="document.name"
            >
              <img src="../img/documentlib.svg" />
              <h3>{{ document.name }}</h3>

              <div class="doc-buttons">
                <b-icon
                  @click="removeUploadedDocument(document.id, document.name)"
                  icon="trash"
                  aria-hidden="true"
                ></b-icon>
                <a :href="`./${document.url}`" target="_blank"
                  ><b-icon
                    @click="`./${document.url}`"
                    icon="eye"
                    aria-hidden="true"
                  ></b-icon
                ></a>
              </div>
            </div>

            <div v-if="filterUploadedDocuments('physioMain').length === 0">
              <div class="empty-state">
                <img src="../img/emptyStateIllustration.svg" />
                <p>No session effort rating data</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>

    <b-modal id="documentModal" size="xl" :hide-footer="Boolean(true)">
      <div class="performance-target-modal-content">
        <h3>New document</h3>

        <form class="form">
          <div class="form__item">
            <label class="form__label" for="title">Title</label>
            <input
              class="form__input"
              type="text"
              id="title"
              v-model="documentData.name"
            />
          </div>

          <wysiwyg v-model="documentData.text" />

          <b-form-checkbox
            id="checkbox-signature"
            v-model="documentData.signature"
            name="checkbox-signature"
          >
            Signature required
          </b-form-checkbox>

          <h4>Document access</h4>

          <div class="form__item">
            <label class="form__label" for="select_season">Team</label>
            <div class="select-user">
              <b-input-group size="sm" class="mb-2 autocomplete">
                <b-input-group-prepend is-text>
                  <b-icon icon="search"></b-icon>
                </b-input-group-prepend>
                <b-form-input
                  type="search"
                  placeholder="Search players / coaches"
                  @input="lookUpSearch($event, 'filterdTeamsData', 'clubTeams')"
                ></b-form-input>
                <ul
                  class="autocomplete-results"
                  v-if="filterdTeamsData.length > 0"
                >
                  <li
                    v-for="teamData in filterdTeamsData"
                    :key="teamData.id"
                    class="autocomplete-result"
                  >
                    <span
                      @click="
                        addUserTeamToApprovedAccessList(
                          'Teams',
                          teamData,
                          'documentData'
                        )
                      "
                    >
                      {{ teamData.teamName }}</span
                    >
                  </li>
                </ul>
              </b-input-group>
            </div>
          </div>

          <div class="form__item">
            <label class="form__label" for="select_season"
              >Player / Coach</label
            >

            <div class="select-user">
              <b-input-group size="sm" class="mb-2 autocomplete">
                <b-input-group-prepend is-text>
                  <b-icon icon="search"></b-icon>
                </b-input-group-prepend>
                <b-form-input
                  type="search"
                  placeholder="Search players / coaches"
                  @input="
                    lookUpSearch(
                      $event,
                      'filterdCoachesUsersData',
                      'coachesUsersData'
                    )
                  "
                ></b-form-input>
                <ul
                  class="autocomplete-results"
                  v-if="filterdCoachesUsersData.length > 0"
                >
                  <li
                    v-for="userData in filterdCoachesUsersData"
                    :key="userData.id"
                    class="autocomplete-result"
                  >
                    <span
                      @click="
                        addUserTeamToApprovedAccessList(
                          'Users',
                          userData,
                          'documentData'
                        )
                      "
                    >
                      {{ userData.name }}</span
                    >
                  </li>
                </ul>
              </b-input-group>
            </div>
          </div>

          <div class="form__item">
            <label class="form__label" for="select_season">Access</label>
            <div class="access-pool">
              <p
                v-for="accessData in documentData.approvedAccessList.users"
                :key="accessData.id"
                class="has-access"
                @click="removeAccess(accessData.id, 'Users')"
              >
                {{ accessData.name }} <span>x</span>
              </p>
              <p
                v-for="accessData in documentData.approvedAccessList.teams"
                :key="accessData.id"
                class="has-access"
                @click="removeAccess(accessData.id, 'Teams')"
              >
                {{ accessData.teamName }} <span>x</span>
              </p>
            </div>
          </div>
        </form>

        <div class="buttons-row">
          <b-button
            size="lg"
            variant="outline-success"
            @click="saveNewDocument()"
          >
            Save</b-button
          >
        </div>
      </div>
    </b-modal>

    <b-modal id="documentViewModal" size="xl" :hide-footer="Boolean(true)">
      <div class="performance-target-modal-content">
        <h3>{{ selectedDocumentData.name }}</h3>

        <div v-html="selectedDocumentData.text"></div>
      </div>
    </b-modal>

    <b-modal id="documentEditModal" size="xl" :hide-footer="Boolean(true)">
      <div class="performance-target-modal-content">
        <h3>Edit document</h3>

        <form class="form">
          <div class="form__item">
            <label class="form__label" for="title">Title</label>
            <input
              class="form__input"
              type="text"
              id="title"
              v-model="selectedDocumentData.name"
            />
          </div>

          <wysiwyg v-model="selectedDocumentData.text" />

          <b-form-checkbox
            id="checkbox-signature"
            v-model="selectedDocumentData.signature"
            name="checkbox-signature"
          >
            Signature required
          </b-form-checkbox>

          <h4>Document access</h4>

          <div class="form__item">
            <label class="form__label" for="select_season">Team</label>
            <div class="select-user">
              <b-input-group size="sm" class="mb-2 autocomplete">
                <b-input-group-prepend is-text>
                  <b-icon icon="search"></b-icon>
                </b-input-group-prepend>
                <b-form-input
                  type="search"
                  placeholder="Search players / coaches"
                  @input="lookUpSearch($event, 'filterdTeamsData', 'clubTeams')"
                ></b-form-input>
                <ul
                  class="autocomplete-results"
                  v-if="filterdTeamsData.length > 0"
                >
                  <li
                    v-for="teamData in filterdTeamsData"
                    :key="teamData.id"
                    class="autocomplete-result"
                  >
                    <span
                      @click="
                        addUserTeamToApprovedAccessList(
                          'Teams',
                          teamData,
                          'selectedDocumentData'
                        )
                      "
                    >
                      {{ teamData.teamName }}</span
                    >
                  </li>
                </ul>
              </b-input-group>
            </div>
          </div>

          <div class="form__item">
            <label class="form__label" for="select_season"
              >Player / Coach</label
            >

            <div class="select-user">
              <b-input-group size="sm" class="mb-2 autocomplete">
                <b-input-group-prepend is-text>
                  <b-icon icon="search"></b-icon>
                </b-input-group-prepend>
                <b-form-input
                  type="search"
                  placeholder="Search players / coaches"
                  @input="
                    lookUpSearch(
                      $event,
                      'filterdCoachesUsersData',
                      'coachesUsersData'
                    )
                  "
                ></b-form-input>
                <ul
                  class="autocomplete-results"
                  v-if="filterdCoachesUsersData.length > 0"
                >
                  <li
                    v-for="userData in filterdCoachesUsersData"
                    :key="userData.id"
                    class="autocomplete-result"
                  >
                    <span
                      @click="
                        addUserTeamToApprovedAccessList(
                          'Users',
                          userData,
                          'selectedDocumentData'
                        )
                      "
                    >
                      {{ userData.name }}</span
                    >
                  </li>
                </ul>
              </b-input-group>
            </div>
          </div>

          <div class="form__item">
            <label class="form__label" for="select_season">Access</label>
            <div class="access-pool">
              <p
                v-for="accessData in selectedDocumentData.approvedAccessList
                  .users"
                :key="accessData.id"
                class="has-access"
                @click="removeAccess(accessData.id, 'Users')"
              >
                {{ accessData.name }} <span>x</span>
              </p>
              <p
                v-for="accessData in selectedDocumentData.approvedAccessList
                  .teams"
                :key="accessData.id"
                class="has-access"
                @click="removeAccess(accessData.id, 'Teams')"
              >
                {{ accessData.teamName }} <span>x</span>
              </p>
            </div>
          </div>
        </form>

        <div class="buttons-row">
          <b-button
            size="lg"
            variant="outline-success"
            @click="saveEditedDocument(selectedDocumentData)"
          >
            Save</b-button
          >
        </div>
      </div>
    </b-modal>

    <b-modal
      id="documentNewEditModal"
      title="Document"
      size="xl"
      :hide-footer="Boolean(true)"
    >
      <div class="performance-target-modal-content">
        <form class="form">
          <h4>Document access</h4>

          <div class="form__item">
            <label class="form__label" for="select_season">Category</label>

            <b-form-select
              v-model="selectedDocumentData.approvedAccessList.category"
              class="mb-3"
            >
              <b-form-select-option :value="null"
                >Please select an option</b-form-select-option
              >
              <b-form-select-option value="club"
                >Club document</b-form-select-option
              >
              <b-form-select-option value="team"
                >Team document</b-form-select-option
              >
              <b-form-select-option value="player"
                >Player document</b-form-select-option
              >
              <b-form-select-option value="admin"
                >Admin document</b-form-select-option
              >
              <b-form-select-option value="physioMain"
                >Physio document</b-form-select-option
              >
            </b-form-select>
          </div>

          <div
            class="form__item"
            v-if="selectedDocumentData.approvedAccessList.category === 'team'"
          >
            <label class="form__label" for="select_season">Team</label>
            <div class="select-user">
              <b-input-group size="sm" class="mb-2 autocomplete">
                <b-input-group-prepend is-text>
                  <b-icon icon="search"></b-icon>
                </b-input-group-prepend>
                <b-form-input
                  type="search"
                  placeholder="Search players / coaches"
                  @input="lookUpSearch($event, 'filterdTeamsData', 'clubTeams')"
                ></b-form-input>
                <ul
                  class="autocomplete-results"
                  v-if="filterdTeamsData.length > 0"
                >
                  <li
                    v-for="teamData in filterdTeamsData"
                    :key="teamData.id"
                    class="autocomplete-result"
                  >
                    <span
                      @click="
                        addUserTeamToApprovedAccessList(
                          'Teams',
                          teamData,
                          'selectedDocumentData'
                        )
                      "
                    >
                      {{ teamData.teamName }}</span
                    >
                  </li>
                </ul>
              </b-input-group>
            </div>
          </div>

          <div
            class="form__item"
            v-if="selectedDocumentData.approvedAccessList.category === 'player'"
          >
            <label class="form__label" for="select_season"
              >Player / Coach</label
            >

            <div class="select-user">
              <b-input-group size="sm" class="mb-2 autocomplete">
                <b-input-group-prepend is-text>
                  <b-icon icon="search"></b-icon>
                </b-input-group-prepend>
                <b-form-input
                  type="search"
                  placeholder="Search players / coaches"
                  @input="
                    lookUpSearch(
                      $event,
                      'filterdCoachesUsersData',
                      'coachesUsersData'
                    )
                  "
                ></b-form-input>
                <ul
                  class="autocomplete-results"
                  v-if="filterdCoachesUsersData.length > 0"
                >
                  <li
                    v-for="userData in filterdCoachesUsersData"
                    :key="userData.id"
                    class="autocomplete-result"
                  >
                    <span
                      @click="
                        addUserTeamToApprovedAccessList(
                          'Users',
                          userData,
                          'selectedDocumentData'
                        )
                      "
                    >
                      {{ userData.name }}</span
                    >
                  </li>
                </ul>
              </b-input-group>
            </div>
          </div>

          <div
            class="form__item"
            style="margin-bottom: 1rem"
            v-if="selectedDocumentData.approvedAccessList.category !== 'all'"
          >
            <label
              v-if="
                selectedDocumentData.approvedAccessList.category !== 'all' &&
                selectedDocumentData.approvedAccessList.category !== 'club' &&
                selectedDocumentData.approvedAccessList.category !== 'admin' &&
                selectedDocumentData.approvedAccessList.category !==
                  'physioMain'
              "
              class="form__label"
              for="select_season"
              >Access</label
            >

            <div
              class="access-pool"
              v-if="
                selectedDocumentData.approvedAccessList.category !== 'all' &&
                selectedDocumentData.approvedAccessList.category !== 'club' &&
                selectedDocumentData.approvedAccessList.category !== 'admin' &&
                selectedDocumentData.approvedAccessList.category !==
                  'physioMain'
              "
            >
              <p
                v-for="accessData in selectedDocumentData.approvedAccessList
                  .users"
                :key="accessData.id"
                class="has-access"
                @click="removeAccess(accessData.id, 'Users')"
              >
                {{ accessData.name }} <span>x</span>
              </p>
              <p
                v-for="accessData in selectedDocumentData.approvedAccessList
                  .teams"
                :key="accessData.id"
                class="has-access"
                @click="removeAccess(accessData.id, 'Teams')"
              >
                {{ accessData.teamName }} <span>x</span>
              </p>
            </div>
          </div>
          <p v-if="selectedDocumentData.approvedAccessList.category === 'team'">
            Only the teams selected will have access.
          </p>
          <p
            v-if="
              selectedDocumentData.approvedAccessList.category === 'club' ||
              selectedDocumentData.approvedAccessList.category === 'admin'
            "
          >
            This document will be avaiable to everyone within the club.
          </p>
          <p
            v-if="selectedDocumentData.approvedAccessList.category === 'player'"
          >
            Only the players selected will have access.
          </p>
          <p v-if="selectedDocumentData.approvedAccessList.category === 'all'">
            If you don't select any access the document will be avaiable to
            everyone.
          </p>
          <p
            v-if="
              selectedDocumentData.approvedAccessList.category === 'physioMain'
            "
          >
            This document will only be avaiable to physio, coaches and Admins.
          </p>
        </form>

        <DocumentUploader
          @getDocumentUploadsCall="getDocumentUploadsCall"
          :documentCategory="selectedDocumentData.approvedAccessList.category"
          :club_id="userData.clubId"
          :user_id="userData.id"
          :selectedDocumentData="selectedDocumentData.approvedAccessList"
        />
      </div>
    </b-modal>

    <b-modal
      id="charterModal"
      modal-class="modal-fullscreen"
      size="xl"
      title="Charter"
      :hide-footer="Boolean(true)"
    >
      <div class="player-cv-content">
        <div class="form__item">
          <label class="form__label" for="contact">Players Charter:</label>
          <wysiwyg v-model="charterData.player_text" />
        </div>

        <div class="form__item">
          <label class="form__label" for="contact">Parents Charter:</label>
          <wysiwyg v-model="charterData.parent_text" />
        </div>

        <b-button
          v-b-tooltip.hover
          title="Update"
          variant="success"
          @click="updateCharterText()"
          >Update charter</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="playerAssesmentModal"
      size="xl"
      modal-class="modal-fullscreen"
      title="U11-18 Player Self Assessment"
      :hide-footer="Boolean(true)"
    >
      <div class="player-cv-content">
        <div class="multi-radio-wrapper">
          <h4>Technical</h4>
          <p>* Please select all that apply</p>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Can Control the ball with both feet in multiple
              directions:</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q1"
              name="radio-options-1"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Can pass with both feet (short):</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q2"
              name="radio-options-2"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Has mastered at least one skill move to beat an opponent:</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q3"
              name="radio-options-3"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Can shoot/cross/pass accurately with both feet:</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q4"
              name="radio-options-4"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Can dribble in multiple directions using both feet:</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q5"
              name="radio-options-5"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Can protect the ball from an opponent:</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q6"
              name="radio-options-6"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>

        <div class="multi-radio-wrapper">
          <h4>Tactical</h4>
          <p>* Please select all that apply</p>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Makes good decisions on the ball:</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q7"
              name="radio-options-7"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Takes up good positions to receive the ball:</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q8"
              name="radio-options-8"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Makes good runs off the ball at the right times:</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q9"
              name="radio-options-9"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Can play in more than one area of the field:</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q10"
              name="radio-options-10"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Can attack in 1v1 situations:</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q11"
              name="radio-options-11"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Can defend in 1v1 situations:</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q12"
              name="radio-options-12"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Reads situations in the game:</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q13"
              name="radio-options-13"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>

        <div class="multi-radio-wrapper">
          <h4>Physical</h4>
          <p>* Please select all that apply</p>

          <div class="form__item">
            <label class="form__label" for="contact">Has good mobility:</label>

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q14"
              name="radio-options-14"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Can Jump and land single leg and double footed:</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q15"
              name="radio-options-15"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Can kick with good technique and generate power (short
              distances):</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q16"
              name="radio-options-16"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Has good balance (single leg, both sides):</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q17"
              name="radio-options-17"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Developing basic speed and strength:</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q18"
              name="radio-options-18"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Is flexible and agile:</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q19"
              name="radio-options-19"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>

        <div class="multi-radio-wrapper">
          <h4>Mental</h4>
          <p>* Please select all that apply</p>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Has a growth mindset, willing to learn:</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q20"
              name="radio-options-20"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Has a competitive mentality:</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q21"
              name="radio-options-21"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Can build positive relationships and friendships:</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q22"
              name="radio-options-22"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Calmly and directly expresses their and feelings about
              situations:</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q23"
              name="radio-options-23"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Is a problem solver, can find positive solutions to
              challenges:</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q24"
              name="radio-options-24"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Emotional control of winning and losing:</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q25"
              name="radio-options-25"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>

        <div class="multi-radio-wrapper">
          <h4>Social</h4>
          <p>* Please select all that apply</p>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Follows Instructions from coaches:</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q26"
              name="radio-options-26"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Respects club and match rules:</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q27"
              name="radio-options-27"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Is a good listener and pays attention to coaches and
              teammates:</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q28"
              name="radio-options-28"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Ignores distractions to focus on tasks:</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q29"
              name="radio-options-29"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Is willing to ask questions to learn:</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q30"
              name="radio-options-30"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Is positive and enjoys being in a group environment with
              others:</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q31"
              name="radio-options-31"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>

          <div class="form__item">
            <label class="form__label" for="contact"
              >Is responsible for their own behaviour:</label
            >

            <b-form-radio-group
              id="radio-group-technical"
              class="custom-control-inline-player-assesment"
              v-model="playerSelfAssessmentU11to18Data.technical.q32"
              name="radio-options-32"
            >
              <b-form-radio value="5">Excellent</b-form-radio>
              <b-form-radio value="4">Good</b-form-radio>
              <b-form-radio value="3">Progressing Well</b-form-radio>
              <b-form-radio value="2">Requires Improvement</b-form-radio>
              <b-form-radio value="1">Unsatisfactory</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>

        <b-button
          v-b-tooltip.hover
          title="Update"
          variant="success"
          @click="updateCodeOfConductText()"
          >Update code of conduct</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="codeOfConductModal"
      modal-class="modal-fullscreen"
      size="xl"
      title="Code of conduct"
      :hide-footer="Boolean(true)"
    >
      <div class="player-cv-content">
        <div class="form__item">
          <wysiwyg v-model="codeOfConduct.text" />
        </div>

        <b-button
          v-b-tooltip.hover
          title="Update"
          variant="success"
          @click="updateCodeOfConductText()"
          >Update code of conduct</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import {
  CHECK_PROFILE_HAS_DATA,
  USER_POPULATE,
  POPULATE_COACHES_USER_DATA,
} from "@/store/actions/user.js";
import { Users } from "@/services/user-service.js";
import { Teams } from "@/services/teams-service.js";
import { Documents } from "@/services/documents-service.js";
import { TrainingPlan } from "@/services/training-plan-service.js";
import AppTemplate from "../layouts/App";
import UserHeader from "../../components/UserHeader";
import SpinnerLoader from "../../components/LoadingSpinning";
import VModal from "vue-js-modal";
import moment from "moment";
import wysiwyg from "vue-wysiwyg";
import removeDuplicatesMixin from "@/mixin/removeDuplicates.js";
import DocumentUploader from "../../components/doucmentUploader";
import Axios from "axios";

Vue.use(VModal);
Vue.use(wysiwyg, {
  hideModules: {
    image: true,
    bold: false,
    italic: false,
    underline: false,
    justifyLeft: false,
    justifyCenter: false,
    justifyRight: false,
    headings: false,
    link: false,
    code: false,
    orderedList: false,
    unorderedList: false,
    image: false,
    table: false,
    removeFormat: false,
    separator: false,
  },
  forcePlainTextOnPaste: true,
});

export default {
  name: "Documents",
  mixins: [removeDuplicatesMixin],
  components: { AppTemplate, SpinnerLoader, DocumentUploader },
  data() {
    return {
      playerSelfAssessmentU11to18Data: {
        technical: {
          q1: 0,
          q2: 0,
          q3: 0,
          q4: 0,
          q5: 0,
          q6: 0,
        },
      },
      codeOfConduct: {
        text: "",
      },
      charterData: {
        player_id: "",
        team_id: "",
        player_text: "",
        parent_text: "",
        created_date: "",
      },
      publicPath: process.env.BASE_URL,
      editMode: false,
      imageFile: null,
      imageToBeRemoved: null,
      imageURL: "",
      image: {
        imageName: "",
        path: "",
        file: null,
      },
      loadingStuff: false,
      trainingPlanHasData: false,
      selectedUserValue: "",
      weekSelectedNumber: "",
      sessionCompleted: false,
      weeklyDate: "",
      newSessionData: {
        userId: "",
        section: "",
        details: "",
        weekSelectedNumber: "",
        name: "",
        introText: "",
        notes: "",
        day: "",
        year: "",
        week: "",
      },
      userHeaderData: {
        name: "",
        userType: "",
      },
      userData: {
        name: "",
        userType: "",
      },
      coachesUsersData: [],
      trainingPlanArciveData: [],
      trainingPlanData: [],
      sessionDataModal: {},
      selectedTab: "club-tab",
      selectedSessionTab: "details",
      documentData: {
        name: "",
        text: "",
        created_date: moment().format("YYYY-MM-DD"),
        updated_date: null,
        created_by: null,
        edited_by: null,
        signature: false,
        approvedAccessList: {
          teams: [],
          users: [],
        },
      },
      filterdCoachesUsersData: [],
      clubTeams: [],
      filterdTeamsData: [],
      usersDocuments: [],
      selectedDocumentData: {
        approvedAccessList: {
          teams: [],
          users: [],
          category: "all",
        },
      },
      uploadedDocuments: [],
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(["getProfile", "getCoachesUsersData", "getWeekNumber"]),
  },
  created() {
    this.$store.dispatch(CHECK_PROFILE_HAS_DATA).then((response) => {
      if (response === true) {
        this.setUser();
        this.getAllUsersForClubId();
        this.getTeamsByUserId();
        this.loadAllDocuments();
      } else {
        this.setSectionType();
        let clientId = JSON.parse(
          localStorage.getItem("the_w_selectedClientId")
        );
        this.getUserById(clientId).then((response) => {
          if (response === true) {
            this.selectedUserValue = clientId;
            this.getAllUsersForClubId();
            this.getTeamsByUserId();
            this.loadAllDocuments();
          }
        });
      }
    });
  },
  methods: {
    removeNumberFromStart(documentName) {
     return documentName.substring(documentName.indexOf('_')+1);
    },
    playerAssesmentModal() {
      this.$bvModal.show("playerAssesmentModal");
    },
    codeOfConductModal() {
      this.showModal("codeOfConductModal");
    },
    async getCodeOfConductData() {
      const userData = {
        action: "getCodeOfConductData",
        clubId: this.userData.clubId,
      };
      const response = await Documents.find(userData).catch((error) => {
        console.log(error);
      });

      if (response.status === 200) {
        this.codeOfConduct = response.data;
      }
    },
    async getCharterData() {
      const userData = {
        action: "getCharterData",
        player_id: 0,
      };
      const response = await Documents.find(userData).catch((error) => {
        console.log(error);
      });

      if (response.status === 200) {
        this.charterData = response.data;
      }
    },
    charterModal() {
      this.showModal("charterModal");
    },
    async updateCodeOfConductText() {
      const userData = {
        action: "updateCodeOfConductText",
        text: this.codeOfConduct.text,
        clubId: this.userData.clubId,
        created_date: moment().format("YYYY-MM-DD"),
        id: this.charterData.id,
      };

      const response = await Documents.find(userData).catch((error) => {
        console.log(error);
      });

      if (response.status === 200) {
        //this.getCodeOfConductData();

        this.$bvModal.hide("codeOfConductModal");
        this.$bvToast.toast("code of conduct updated", {
          title: "Saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async updateCharterText() {
      const userData = {
        action: "updateCharterText",
        player_id: 0,
        team_id: 0,
        player_text: this.charterData.player_text,
        parent_text: this.charterData.parent_text,
        created_date: moment().format("YYYY-MM-DD"),
        id: this.charterData.id,
      };

      const response = await Documents.find(userData).catch((error) => {
        console.log(error);
      });

      if (response.status === 200) {
        this.getCharterData();

        this.$bvModal.hide("charterModal");
        this.$bvToast.toast("Charter updated", {
          title: "Saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    filterUploadedDocuments(categorySelected) {
      return this.uploadedDocuments.filter(
        (document) => document.category === categorySelected
      );
    },
    async removeUploadedDocument(id, name) {
      const userData = {
        action: "removeDocumentUpload",
        id: id,
        name: name,
      };
      const response = await Documents.find(userData).catch((error) => {
        console.log(error);
      });

      if (response.status === 200) {
        this.getDocumentUploadsCall();
        this.$bvModal.hide("documentEditModal");

        this.$bvToast.toast("Document successfully deleted.", {
          title: "Deleted",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async sendFile() {
      let API_SERVER = "";
      if (window.location.href.indexOf("8080") > -1) {
        API_SERVER = "http://www.localhost:8888/upload-document.php";
      } else {
        API_SERVER = "/personal-training-BE/upload-document.php";
      }
      const formData = new FormData();
      formData.append("pdf", this.fileUploadData);

      Axios.post(API_SERVER, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((responce) => {
          if (responce.status === 200) {
            this.getDocumentUploadsCall();

            this.$bvToast.toast("Document uploaded.", {
              title: "Uploaded",
              toaster: "b-toaster-bottom-right",
              solid: true,
              appendToast: true,
              variant: "success",
            });
          }
        })
        .catch(() => {});
    },
    async getDocumentUploadsCall() {
      this.$bvModal.hide("documentNewEditModal");
      const userData = {
        action: "getDocumentUploads",
      };
      const response = await Documents.find(userData).catch((error) => {
        console.log(error);
      });

      if (response.status === 200) {
        this.uploadedDocuments = response.data;
      }
    },
    async getDocumentAccessDataCall(documentId) {
      const userData = {
        document_id: documentId,
        action: "getDocumentAccess",
      };
      const response = await Documents.find(userData).catch((error) => {
        console.log(error);
      });

      if (response.data.length > 0) {
        return response.data;
      } else {
        return null;
      }
    },
    async populateDocumentModelWithAccess(
      documentData,
      documentAccesData,
      objectName,
      modalName
    ) {
      if (documentAccesData !== null) {
        let accessResponse = documentAccesData.map(async (accessData) => {
          const teamData = await this.getClubTeamName(accessData.team_id);
          if (accessData.team_id !== null) {
            return await this.addUserTeamToApprovedAccessList(
              "Teams",
              teamData,
              objectName
            );
          }

          if (accessData.user_id !== null) {
            const response = await this.getUserName(accessData.user_id);
            const useAceessrData = {
              id: accessData.user_id,
              name: response.name,
            };
            return await this.addUserTeamToApprovedAccessList(
              "Users",
              useAceessrData,
              objectName
            );
          }
        });

        if (accessResponse) {
          this[objectName] = {
            ...documentData,
            approvedAccessList: { ...this[objectName].approvedAccessList },
          };
          this.showModal(modalName);
        }
      }
    },
    async openDocument(documentData) {
      const documentAccesData = await this.getDocumentAccessDataCall(
        documentData.id
      );

      if (documentAccesData.length > 0) {
        this.populateDocumentModelWithAccess(
          documentData,
          documentAccesData,
          "selectedDocumentData",
          "documentViewModal"
        );
      }
    },
    async editDocument(documentData) {
      const documentAccesData = await this.getDocumentAccessDataCall(
        documentData.id
      );

      await this.populateDocumentModelWithAccess(
        documentData,
        documentAccesData,
        "selectedDocumentData",
        "documentEditModal"
      );
    },
    getUserName(id) {
      return this.coachesUsersData.find((user) => user.id == id) || null;
    },
    getClubTeamName(id) {
      return this.clubTeams.find((team) => team.id == id) || null;
    },
    async loadAllDocuments() {
      this.getDocumentUploadsCall();
    },
    async addDocumetsAccess() {
      const documentAccessData = {
        approvedAccessList: this.selectedDocumentData.approvedAccessList,
        document_id: this.selectedDocumentData.id,
        action: "addDocumetsAccess",
      };

      const response = await Documents.find(documentAccessData).catch(
        (error) => {
          console.log(error);
        }
      );

      if (response.data.length > 0) {
        this.loadAllDocuments();
      }
    },
    async addNewDocumentUploadData() {
      this.loadingStuff = true;

      const userData = {
        ...this.selectedDocumentData,
        action: "addNewDocumentUploadData",
      };
      /*const response = await Documents.find(userData).catch((error) => {
        console.log(error);
      });*/
    },
    async saveEditedDocument() {
      this.loadingStuff = true;

      this.selectedDocumentData.signature === true ?? 1;
      this.selectedDocumentData.signature === false ?? 0;
      this.selectedDocumentData.updated_date === moment().format("YYYY-MM-DD");

      const userData = {
        ...this.selectedDocumentData,
        action: "saveEditedDocument",
      };
      const response = await Documents.find(userData).catch((error) => {
        console.log(error);
      });

      if (response.data === "Document updated") {
        await this.addDocumetsAccess();
        this.loadingStuff = false;
        this.$bvToast.toast("Document updated successfully.", {
          title: "Updated",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });

        this.loadingStuff = false;
        this.hideModal("documentEditModal");
      }
    },
    async saveNewDocument() {
      this.loadingStuff = true;
      this.documentData.created_by = this.userData.id;

      const userData = {
        ...this.documentData,
        action: "addDocument",
      };
      const response = await Documents.find(userData).catch((error) => {
        console.log(error);
      });

      if (response.data.length > 0) {
        this.hideModal("documentModal");
        this.loadAllDocuments();
        this.loadingStuff = false;
        this.$bvToast.toast("Created Document successfully.", {
          title: "Created",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async removeAccessCall(accessDataToBeRemoved) {
      this.loadingStuff = true;
      const data = {
        ...accessDataToBeRemoved,
        action: "removeDocumentAccess",
      };
      const response = await Documents.find(data).catch((error) => {
        console.log(error);
      });

      if (response === "access removed") {
        console.log(response);
      }

      const documentAccesData = await this.getDocumentAccessDataCall(
        this.selectedDocumentData.id
      );
      this.selectedDocumentData.documentAccesData = documentAccesData;
      this.loadingStuff = false;
    },
    removeAccess(id, typeToRemove) {
      let accessToRemove = {
        teams: [],
        users: [],
      };

      if (typeToRemove === "Teams") {
        this.selectedDocumentData.approvedAccessList.teams =
          this.selectedDocumentData.approvedAccessList.teams.filter(
            (team) => team.id !== id
          );
        accessToRemove.teams.push({
          team_id: id,
          document_id: this.selectedDocumentData.id,
        });
      }
      if (typeToRemove === "Users") {
        this.selectedDocumentData.approvedAccessList.users =
          this.selectedDocumentData.approvedAccessList.users.filter(
            (user) => user.id !== id
          );
        accessToRemove.users.push({
          user_id: id,
          document_id: this.selectedDocumentData.id,
        });
      }
      if (this.selectedDocumentData.id !== undefined) {
        this.removeAccessCall(accessToRemove);
      }
    },
    addToApprovedAccessList(accessList, newItem) {
      if (!accessList.some((item) => item.id == newItem.id)) {
        accessList.push(newItem);
      }
    },
    addUserTeamToApprovedAccessList(accessType, data, objectName) {
      if (accessType === "Teams") {
        this.addToApprovedAccessList(
          this[objectName].approvedAccessList.teams,
          {
            id: data.id,
            teamName: data.teamName,
          }
        );
        this.filterdTeamsData = [];
        return this[objectName].approvedAccessList.teams.length > 0 ?? true;
      }

      if (accessType === "Users") {
        this.addToApprovedAccessList(
          this[objectName].approvedAccessList.users,
          {
            id: data.id,
            name: data.name,
          }
        );
        this.filterdCoachesUsersData = [];
        return this[objectName].approvedAccessList.users.length > 0 ?? true;
      }
    },
    async getAllUsersForClubId() {
      const userData = {
        clubId: this.userData.clubId,
        action: "getUsersByClubId",
      };
      const response = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      if (response.data.length > 0) {
        this.coachesUsersData = response.data;
      }
    },
    async getTeamsByUserId() {
      const data = {
        action: "getTeamsDataById",
        userId: this.userData.id,
      };

      if (
        this.userData.userType === "Admin" ||
        this.userData.userType === "Coach"
      ) {
        data.clubId = this.userData.clubId;
      }
      const response = await Teams.find(data).catch((error) => {
        console.log(error);
      });
      this.clubTeams = this.removeDuplicates(response.data, "id");
    },
    lookUpSearch(value, filterdData, arrayToFilter) {
      if (value.length > 3) {
        this[filterdData] = [];

        const results = Array.from(value).reduce(
          (a, v, i) => `${a}[^${value.substr(i)}]*?${v}`,
          ""
        );
        const regEx = RegExp(results);

        this[arrayToFilter].filter((v) => {
          if (v.hasOwnProperty("name")) {
            if (v.name.match(regEx)) {
              this[filterdData].push(v);
            }
          }

          if (v.hasOwnProperty("teamName")) {
            if (v.teamName.match(regEx)) {
              this[filterdData].push(v);
            }
          }
        });
      } else {
        this[filterdData] = [];
      }
    },
    setSectionType() {
      switch (this.$route.name) {
        case "documents":
          this.newSessionData.section = "Documents";
          break;
      }
    },
    getTrainingTypeAndUserData() {
      if (
        this.userData.userType === "Admin" ||
        this.userData.userType === "Coach"
      ) {
        return {
          trainingType: this.$route.params.trainingPlanType,
          userId: this.$route.params.trainingPlanUserId,
        };
      } else {
        this.selectedUserValue = this.userData.id;
        return {
          trainingType: this.$route.params.trainingPlanType,
          userId: this.userData.id,
        };
      }
      return null;
    },
    showModal(modelId) {
      this.$bvModal.show(modelId);
    },
    hideModal(modelId) {
      this.$bvModal.hide(modelId);
    },
    tabChange(tabValue, selectedTabValue) {
      this[tabValue] = selectedTabValue;

      if (selectedTabValue === "global-tab") {
        this.getCharterData();
        this.getCodeOfConductData();
      }
    },
    selectedUseChanged(userId) {
      this.selectedUserValue = userId.selectedUser;
    },
    weekSelectedChanged(number) {
      this.weekSelectedNumber = number.weekSelectedNumber;
    },
    async getUserByIdForHeader(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const response = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      if (response.data.length > 0) {
        this.userHeaderData = response.data[0];
      }
    },
    async getUserById(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const response = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (response.data.length > 0) {
        this.userData = response.data[0];
        const userData = response.data[0];
        this.$store.dispatch(USER_POPULATE, { userData });
        if (
          this.userData.userType === "Admin" ||
          this.userData.userType === "Coach"
        ) {
          this.checkForCoachesUsersData();
        }
        return true;
      }
    },
    setUser() {
      if (this.getProfile !== null) {
        this.userData = this.getProfile;
      }
    },
    setHeaderUserData() {
      if (this.getProfile !== null) {
        this.userHeaderData = this.getProfile;
      }
    },
    makeTraingDataObject(actionName) {
      let data = this.newSessionData;
      data.complete = false;
      data.action = actionName;

      if (this.image.file !== null) {
        data.imageFile = this.imageFile;
        data.fileName = this.image.imageName;
      }
      return data;
    },
    actionsAfterFitnessSessionEditCreate() {
      this.hideModal("addEditProgramme");
      this.gettrainingPlansByUserIdAndWeek(this.selectedUserValue);
      this.loadingStuff = false;
      this.$bvToast.toast("Created successfully.", {
        title: "Created",
        toaster: "b-toaster-bottom-right",
        solid: true,
        appendToast: true,
        variant: "success",
      });
    },
    async edittrainingPlan() {
      this.loadingStuff = true;
      const trainingPlanData = this.makeTraingDataObject("edittrainingPlan");
      const response = await TrainingPlan.store(trainingPlanData).catch(
        (error) => {
          console.log(error);
        }
      );

      if (response === "Training session updated") {
        this.actionsAfterFitnessSessionEditCreate();
        this.editMode = false;
      }
    },
    async getAllUsersForThisAdmin() {
      const userData = {
        coachId: this.userData.id,
        action: "getUserByCoachId",
      };
      const response = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (response.data.length > 0) {
        this.coachesUsersData = response.data;
        this.$store.dispatch(POPULATE_COACHES_USER_DATA, this.coachesUsersData);
        localStorage.setItem(
          "coachesUsersData",
          JSON.stringify(this.coachesUsersData)
        );
      }
    },
    checkForCoachesUsersData() {
      if (this.getCoachesUsersData.length > 0) {
        this.coachesUsersData = this.getCoachesUsersData;
      } else {
        if (
          this.userData.userType === "Admin" ||
          this.userData.userType === "Coach"
        ) {
          this.getAllUsersForThisAdmin();
        }
      }
    },
    makeToast(title, message, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.document-uploads-tab {
  padding: 2rem;
}

.signed-documents-wrapper {
  display: block;

  .signed-documents {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
}
.tabs-wrapper .tab-content {
  padding: 1rem;
}

.sorting-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: start;

  .filter-by {
    background: transparent;
  }

  .search {
    max-width: 20rem;

    input {
      padding: 0.5rem;
      font-size: 0.875rem;
      border-radius: 0;
      background: "white";
    }

    .input-group-text {
      padding: 0.5rem;
      border-radius: 0;
    }
  }
}

.access-pool {
  padding: 1rem;
  border: 1px solid #dddddd;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  .has-access {
    background: #e3f0fb;
    padding: 0.5rem;
    display: block;
    border-radius: 10px;
    cursor: pointer;

    &:hover,
    &:focus {
      background: #f8e0e0;
    }
  }
}

.buttons-row {
  margin-top: 1rem;
  padding-top: 1rem;
}

.file-upload {
  border: 1px solid #dddddd;
  padding: 1rem;
  margin-bottom: 1rem;
}

.documents-tab {
  display: flex;
  flex-wrap: wrap;

  .document {
    max-width: 14rem;
    text-align: center;
    padding: 1rem;
    margin: 1rem;
    border-radius: 4px;
    box-shadow: 0 4px 16px rgba(138, 138, 138, 0.3);
    border: 1px solid transparent;

    &:hover,
    &:focus {
      border: 1px solid #a1152f;
    }

    h3 {
      margin-top: 1rem;
      font-size: 1rem;
      overflow: hidden;
      white-space: nowrap;
      text-transform: inherit;
    }

    img {
      width: 40%;
    }
  }
}

.doc-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;

  svg {
    border: 1px solid #c3cdd5;
    padding: 0.3rem;
    font-size: 1.7rem;

    &:hover,
    &:focus {
      cursor: pointer;
      background: #eaeff3;
    }
  }
}
</style>
