var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('spinner-loader',{attrs:{"loadingStuff":_vm.loadingStuff}}),_c('app-template'),(_vm.userData.name !== '')?_c('div',{staticClass:"uk-container uk-container-large"},[_c('h1',[_vm._v("Help & support")]),_c('div',{staticClass:"sessions-week-layout"},[_c('ul',{staticClass:"tabs"},[_c('li',{staticClass:"tab-item"},[_c('a',{class:_vm.selectedTab === 'contact-tab'
                ? 'tab-link active'
                : 'tab-link',attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.tabChange('selectedTab', 'contact-tab')}}},[_vm._v("Contact")])]),_c('li',{staticClass:"tab-item"},[_c('a',{class:_vm.selectedTab === 'tutorials-tab'
                ? 'tab-link active'
                : 'tab-link',attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.tabChange('selectedTab', 'tutorials-tab')}}},[_vm._v("Tutorials")])])]),_c('div',{staticClass:"tabs-wrapper"},[(_vm.selectedTab === 'contact-tab')?_c('div',{staticClass:"current-week-tab documents-tab tab-content"},[_vm._m(0)]):_vm._e(),(_vm.selectedTab === 'tutorials-tab')?_c('div',{staticClass:"current-week-tab documents-tab tab-content"},[_vm._m(1)]):_vm._e()])])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Contact support: "),_c('a',{attrs:{"href":"mailTo:bigs@stewartpaxton.co.uk"}},[_vm._v("email")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tutorials-wrapper"},[_c('h3',[_vm._v("Player / parents tutorials")])])
}]

export { render, staticRenderFns }